import Sandbox from '@components/Sandbox';
import { createIpaPlatform, guestLogin } from '@services/youtube-platform.service';
import { useContext, useEffect, useState } from 'react';
import { setAuth } from '@services/identity.service';
import { TeamsDataContext } from '@components/context/TeamsContext';
import { useTheme } from 'next-themes';
import { isSandbox } from '@lib/sandbox-helpers';

export default function SandboxPage({
  user,
  platform,
  templateId,
  interaction
}) {
  const [platformId, setPlatformId] = useState(null);
  const { teamContext, initTeam } = useContext(TeamsDataContext);
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    setAuth(user);
    initTeam();
  }, []);

  useEffect(() => {
    if (!isSandbox()) return;

    if (theme === 'light') return;

    setTheme('light');
  }, [theme]);

  const findOrCreateSandboxPlatform = async () => {
    const res = await createIpaPlatform({ userId: teamContext?.oid });
    setPlatformId(res.entity[0].platform_id);
  };

  useEffect(() => {
    if (teamContext?.oid) {
      findOrCreateSandboxPlatform();
    }
  }, [teamContext]);

  if (!platformId) return null;

  return <Sandbox
    platform={platform}
    platformId={platformId}
    interaction={interaction}
    templateId={templateId}
  />;
}

export const getServerSideProps = async ({ query }) => {
  try {
    if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
      throw new Error('Sandbox is not available in production');
    }

    const { platform = 'zoom', interaction = 0, templateId = '54e37f08-2ae7-4a32-ac8c-ba60fe5d5d0b' } = query;

    const res = await guestLogin({ email: 'sandbox@streamalive.com' });

    return {
      props: {
        platform,
        interaction,
        templateId,
        user: res.entity[0]
      }
    };
  } catch (error) {
    return {
      redirect: {
        destination: '/'
      }
    };
  }
};
